import React, { useState, useEffect } from 'react';
import useAPI from '../../hooks/UseAPI';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { Modal } from "../Modal/Modal";
import './userList.style.css';

interface FormErrors {
    confirmPassword?: string;
}

export const UserList: React.FC = () => {
    const [users, setUsers] = useState<string[]>([]);
    const [isAddUserOpen, setIsAddUserOpen] = useState(false);
    const [deleteUserEmail, setDeleteUserEmail] = useState<string | null>(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const token = sessionStorage.getItem("jwtToken");

    const getUsersAPI = useAPI({
        url: "user/getuserlist",
        method: "GET",
        auth: true,
        token: token ? token : "",
    });

    const createUserAPI = useAPI({
        url: "user/createnewuser",
        method: "POST",
        auth: true,
        token: token ? token : "",
    });

    const deleteUserAPI = useAPI({
        url: `user/deleteuser/${encodeURIComponent(deleteUserEmail || '')}`,
        method: "GET",
        auth: true,
        token: token ? token : "",
    });

    useEffect(() => {
        getUsersAPI.call();
    }, []);

    useEffect(() => {
        if (getUsersAPI.data?.users) {
            setUsers(getUsersAPI.data.users);
        }
    }, [getUsersAPI.data]);

    const validateForm = (password: string, confirmPassword: string) => {
        const errors: FormErrors = {};
        if (password !== confirmPassword) {
            errors.confirmPassword = 'Hasła nie są takie same';
        }
        return errors;
    };

    const handleAddUser = async (e: React.FormEvent) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        const email = formData.get('email') as string;
        const password = formData.get('password') as string;
        const confirmPassword = formData.get('confirmPassword') as string;

        const errors = validateForm(password, confirmPassword);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const result = await createUserAPI.call({
                body: { email, password, confirmPassword }
            });

            if (result?.response && result.response.ok) {
                setIsAddUserOpen(false);
                setFormErrors({});
                getUsersAPI.call();
            }
        }
    };

    const handleDeleteUser = async () => {
        if (!deleteUserEmail) return;

        const result = await deleteUserAPI.call();

        if (result?.response && result.response.ok) {
            setShowDeleteConfirm(false);
            setDeleteUserEmail(null);
            getUsersAPI.call();
        }
    };

    const AddUserForm = () => (
        <div className="upload-modal">
            <h2>Dodaj nowego użytkownika</h2>
            <form onSubmit={handleAddUser}>
                <div className="form-group">
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        name="password"
                        placeholder="Hasło"
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        name="confirmPassword"
                        placeholder="Powtórz hasło"
                        required
                    />
                    {formErrors.confirmPassword && <div className="error-text">{formErrors.confirmPassword}</div>}
                </div>
                <div className="upload-modal-buttons">
                    <button type="button" className="close-button" onClick={() => setIsAddUserOpen(false)}>
                        <FaTimes /> Anuluj
                    </button>
                    <button type="submit" className="upload-button">
                        <FaPlus /> Dodaj
                    </button>
                </div>
            </form>
        </div>
    );

    const DeleteConfirmation = () => (
        <div className="upload-modal">
            <h2>Potwierdź usunięcie</h2>
            <p>Czy chcesz usunąć użytkownika {deleteUserEmail}?</p>
            <div className="upload-modal-buttons">
                <button className="close-button" onClick={() => setShowDeleteConfirm(false)}>
                    <FaTimes /> Anuluj
                </button>
                <button className="upload-button" onClick={handleDeleteUser}>
                    Usuń
                </button>
            </div>
        </div>
    );

    return (
        <div className="background-image-manager">
            <div className="user-list-header">
                <h2>LISTA UŻYTKOWNIKÓW</h2>
                <div className="header-buttons">
                    <button onClick={() => setIsAddUserOpen(true)} className="add-event-button">
                        <FaPlus /> Dodaj użytkownika
                    </button>
                </div>
            </div>

            {getUsersAPI.error && (
                <div className="error-message">
                    {getUsersAPI.error}
                </div>
            )}

            <div className="user-grid">
                {getUsersAPI.loading ? (
                    <div className="loading">Ładowanie...</div>
                ) : users.map((email) => (
                    <div key={email} className="user-item">
                        <span>{email}</span>
                        <button
                            onClick={() => {
                                setDeleteUserEmail(email);
                                setShowDeleteConfirm(true);
                            }}
                            className="delete-icon"
                        >
                            <FaTimes />
                        </button>
                    </div>
                ))}
            </div>

            {isAddUserOpen && (
                <Modal>
                    <AddUserForm />
                </Modal>
            )}

            {showDeleteConfirm && deleteUserEmail && (
                <Modal>
                    <DeleteConfirmation />
                </Modal>
            )}
        </div>
    );
};

export default UserList;