import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, startOfMonth, endOfMonth, parseISO } from 'date-fns';
import { pl } from 'date-fns/locale';
import MainPhoto from "../MainPhoto/MainPhoto";
import useAPI from '../../hooks/UseAPI';
import { FaPen, FaEye, FaEyeSlash, FaPlus, FaChevronDown, FaChevronUp,FaRegHandPointer,FaCopy} from 'react-icons/fa';
import { EditPopup } from '../EditPopup/EditPopup';
import { CreateEventPopup } from '../Events/CreateEventPopup';
import { Calendar } from './Calendar';
import DOMPurify from 'dompurify';
import Notification from './Notification';
import NotificationEditor from './NotificationEditor';
import { Modal } from "../Modal/Modal";
import CopyEvent from './CopyEvent'
import './welcomePage.style.css';
import { jwtDecode } from "jwt-decode";
import UserList from './UserList';



interface Event {
    id: number;
    eventPath: string;
    eventName: string;
    eventPlace: string;
    eventDate: string;
    eventDescription: string;
    eventVisibility: boolean;
}

interface RegistrationData {
    numberOfRegistrations: number;
    totalNumberOfRegistrations: number;
    totalRegistrations: number;
}
interface JWTPayload {
    iat: number;
    exp: number;
    roles: string[];
    username: string;
}
export const WelcomePage: React.FC = () => {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sortOrder, setSortOrder] = useState('ASC');
    const [page, setPage] = useState(1);
    const [isScrolled, setIsScrolled] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [showCreateEventPopup, setShowCreateEventPopup] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
    const [expandedEventId, setExpandedEventId] = useState<number | null>(null);
    const [registrationData, setRegistrationData] = useState<{ [key: string | number]: RegistrationData }>({});
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [calendarEvents, setCalendarEvents] = useState<Event[]>([]);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [isInstructionOpen, setIsInstructionOpen] = useState(false);
    const [showNotificationEditor, setShowNotificationEditor] = useState(false);
    const [notificationContent, setNotificationContent] = useState('');
    const editPopupRef = useRef<HTMLDivElement>(null);
    const createEventPopupRef = useRef<HTMLDivElement>(null);
    const notificationEditorRef = useRef<HTMLDivElement>(null);
    const userListPopUpref = useRef<HTMLDivElement>(null);
    const [isCopyEventOpen, setCopyEventOpen] = useState(false);
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [showUserList, setShowUserList] = useState(false);
    const token = sessionStorage.getItem("jwtToken");

    const eventsAPI = useAPI({
        url: `events/getevents?page=${page}&startDate=${startDate}&endDate=${endDate}&sortOrder=${sortOrder}`,
        method: "GET",
        auth: true,
        token: token ? token : "",
    });

    const calendarEventsAPI = useAPI({
        url: `events/getevents?page=1&elementsPerPage=100&startDate=${format(startOfMonth(currentMonth), 'yyyy-MM-dd')}&endDate=${format(endOfMonth(currentMonth), 'yyyy-MM-dd')}&sortOrder=ASC`,
        method: "GET",
        auth: true,
        token: token ? token : "",
    });
    const getNotificationAPI = useAPI({
        url: "welcomepage/getinstructioncontent",
        method: "GET",
        auth: true,
        token: token ? token : "",
    });
    const tokenValidate = useAPI({
        url: "user/validatetoken",
        method: "GET",
        auth: true,
        token: token ? token : "",
    });

    const setVisibilityAPI = useAPI({
        url: "events/setvisibility",
        method: "POST",
        auth: true,
        token: token ? token : "",
    });
    const extractRolesFromToken = (token: string): string[] => {
        try {
            const decodedToken = jwtDecode<JWTPayload>(token);
            return decodedToken.roles || [];
        } catch (error) {
            console.error("Error decoding token:", error);
            return [];
        }
    };
    const fetchNotificationContent = () => {
        getNotificationAPI.call().then((res) => {
            if (res?.response && res.response.ok) {
                setNotificationContent(res.data.content);
            }
        });
    };
    useEffect(() => {
        fetchNotificationContent();
    }, []);
    useEffect(() => {
        eventsAPI.call();
    }, [page, startDate, endDate, sortOrder]);

    useEffect(() => {
        calendarEventsAPI.call();
    }, [currentMonth]);

    useEffect(() => {
        const handleScroll = () => setIsScrolled(window.scrollY > 0);
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (token) {
            tokenValidate.call().then((res) => {
                if (res?.response !== undefined && res.response.ok) {
                    const roles = extractRolesFromToken(token);
                    setUserRoles(roles);
                    setIsAdmin(roles.includes('ROLE_ADMIN'));
                    setIsUser(roles.includes('ROLE_USER'));
                } else {
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    setUserRoles([]);
                    setIsAdmin(false);
                    setIsUser(false);
                    window.location.reload();
                }
            });
        }
    }, [token]);

    useEffect(() => {
        if (calendarEventsAPI.data && calendarEventsAPI.data.events) {
            setCalendarEvents(calendarEventsAPI.data.events);
        }
    }, [calendarEventsAPI.data]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (editPopupRef.current && !editPopupRef.current.contains(event.target as Node)) {
                setShowEditPopup(false);
            }
            if (createEventPopupRef.current && !createEventPopupRef.current.contains(event.target as Node)) {
                setShowCreateEventPopup(false);
            }
            if (notificationEditorRef.current && !notificationEditorRef.current.contains(event.target as Node)) {
                setShowNotificationEditor(false);
            }
            if (userListPopUpref.current && !userListPopUpref.current.contains(event.target as Node)){
                setShowUserList(false)
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleCreateEvent = () => {
        setShowCreateEventPopup(true);
    };

    const handleCloseCreateEventPopup = () => {
        setShowCreateEventPopup(false);
    };

    const handleCopyEvent = (event: Event) => {
        setSelectedEvent(event);
        setCopyEventOpen(true);
    };
    const onCopyEvent = () => {
        handleCloseCopyEvent();
    };

    const handleCloseCopyEvent = () => {
        setCopyEventOpen(false);
    };
    const onEventCreated = () => {
        setShowCreateEventPopup(false);
        eventsAPI.call();
        calendarEventsAPI.call();
    };

    const toggleSortOrder = () => {
        setSortOrder(prevOrder => prevOrder === 'ASC' ? 'DESC' : 'ASC');
    };

    const handleEdit = (event: Event) => {
        setSelectedEvent(event);
        setShowEditPopup(true);
    };


    const handleToggleVisibility = (eventId: number, currentVisibility: boolean) => {
        const newVisibility = !currentVisibility;
        setVisibilityAPI.call({
            body: {
                eventId: eventId,
                visible: newVisibility
            }
        }).then((res) => {
            if (res?.response != undefined && res.response.ok) {
                const updatedEvents = eventsAPI.data.events.map((event: Event) =>
                    event.id === eventId ? {...event, eventVisibility: newVisibility} : event
                );
                eventsAPI.data.events = updatedEvents;
                setCalendarEvents(prevEvents =>
                    prevEvents.map(event =>
                        event.id === eventId ? { ...event, eventVisibility: newVisibility } : event
                    )
                );
            }
        });
    };

    const handleGetRegistrations = (eventId: number) => {
        if ((isAdmin)) {
            if (expandedEventId === eventId) {
                setExpandedEventId(null);
            } else {
                const headers = new Headers();
                headers.append('Authorization', `Bearer ${token}`);
                fetch(`${process.env.REACT_APP_API_URL}events/getregistrations/${eventId}`, {
                    method: 'GET',
                    headers: headers,
                })
                    .then(response => {
                        if (!response.ok) throw new Error('Network response was not ok');
                        return response.json();
                    })
                    .then(data => {
                        setRegistrationData(prev => ({ ...prev, [eventId]: data }));
                        setExpandedEventId(eventId);
                    })
                    .catch(error => console.error("Error fetching registrations:", error));
            }
        }
    };

    const handleShowAll = () => {
        setStartDate('');
        setEndDate('');
        setPage(1);
        setSortOrder('ASC');
        setSelectedDate(null);
        eventsAPI.call();
    };


    const truncateDescription = (description: string, maxLength: number) => {
        const decodedText = description
            .replace(/&nbsp;/g, ' ')
            .replace(/&amp;/g, '&')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"')
            .replace(/&#39;/g, "'");

        const plainText = DOMPurify.sanitize(decodedText, {
            ALLOWED_TAGS: [],
            KEEP_CONTENT: true
        });

        return plainText.length <= maxLength
            ? plainText
            : plainText.substring(0, maxLength) + '...';
    };

    const formatEventDate = (dateString: string) => {
        const date = parseISO(dateString);
        const formattedDate = format(date, 'dd.MM.yyyy', { locale: pl });
        const formattedTime = format(date, 'HH:mm', { locale: pl });
        return { date: formattedDate, time: formattedTime };
    };

    const handleDateSelect = (date: Date | null) => {
        setSelectedDate(date);
        if (date) {
            setStartDate(format(date, 'yyyy-MM-dd'));
            setEndDate(format(date, 'yyyy-MM-dd'));
        } else {
            setStartDate('');
            setEndDate('');
        }
        setPage(1);
    };

    const handleMonthChange = (newMonth: Date) => {
        setCurrentMonth(newMonth);
        setStartDate(format(startOfMonth(newMonth), 'yyyy-MM-dd'));
        setEndDate(format(endOfMonth(newMonth), 'yyyy-MM-dd'));
        setPage(1);
    };

    const handleEditContent = () => {
        setShowNotificationEditor(true);
    };

    const handleCloseEditor = () => {
        setShowNotificationEditor(false);
    };

    const handleUpdateNotification = () => {
        fetchNotificationContent();
        setShowNotificationEditor(false);
    };

    const isMobile = window.innerWidth <= 768;

    const renderEvent = (event: Event) => {
        const { date, time } = formatEventDate(event.eventDate);
        return (
            <div key={event.id} className="event-item">
                <div
                    className="event-content"
                    onClick={() => navigate(`/event/${event.id}/${encodeURIComponent(event.eventPath)}`)}
                >
                    {isMobile ? (
                        <>
                            <div className="event-datetime">
                                <div>Data: {date}</div>
                                <div>Godzina: {time}</div>
                            </div>
                            <h3>{event.eventName}</h3>
                            <p>{truncateDescription(event.eventDescription, 100)}</p>
                        </>
                    ) : (
                        <>
                            <div className="event-header">
                                <h3>{event.eventName}</h3>
                                <div className="event-datetime">
                                    <div>Data: {date}</div>
                                    <div>Godzina: {time}</div>
                                </div>
                            </div>
                            <p>{truncateDescription(event.eventDescription, 150)}</p>
                        </>
                    )}
                    {!(isAdmin) && (
                        <div className="non-admin-info">
                            <FaRegHandPointer className="tap-icon" />
                            <span>

                            {isMobile ? "Kliknij aby dowiedzieć się więcej" : "Kliknij w wydarzenie aby dowiedzieć się o nim więcej"}
                            </span>

                        </div>
                    )}
                </div>
                {isAdmin && (
                    <div className="admin-actions">
                        <button onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(event);
                        }} title="Edit" className="action-button edit-button">
                            <FaPen/>
                        </button>
                        <button onClick={(e) => {
                            e.stopPropagation();
                            handleCopyEvent(event);
                        }} title="Copy" className="action-button copy-button">
                            <FaCopy/>
                        </button>
                        <button onClick={(e) => {
                            e.stopPropagation();
                            handleToggleVisibility(event.id, event.eventVisibility);
                        }}
                                title={event.eventVisibility ? "Hide" : "Show"}
                                className="action-button visibility-button">
                            {event.eventVisibility ? <FaEye/> : <FaEyeSlash/>}
                        </button>
                        <button onClick={(e) => {
                            e.stopPropagation();
                            handleGetRegistrations(event.id);
                        }}
                                title="Get Registrations"
                                className="action-button info-button">
                            {expandedEventId === event.id ? <FaChevronUp/> : <FaChevronDown/>}
                        </button>
                    </div>
                )}

                {expandedEventId === event.id && registrationData[event.id] && (
                    <div className="registration-data">
                        <p>Rejestracji: {registrationData[event.id].totalNumberOfRegistrations}</p>
                        <p>Zajętych miejsc: {registrationData[event.id].totalRegistrations}</p>
                        <p>Unikatowych zapisów: {registrationData[event.id].numberOfRegistrations}</p>
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            <div className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
            </div>
            <MainPhoto showAdminOptions={isAdmin} token={token} />
            <div className="scrolled-background">

                <div className="main">
                    {isAdmin && (
                        <div className="temp-users-management">
                            <h2 className="temp-user-title">ZARZĄDZAJ UŻYTKOWNIKAMI TYMCZASOWYMI</h2>
                            <button
                                onClick={() => setShowUserList(true)}
                                className="temp-users-button"
                            >
                                Przejdź do listy
                            </button>
                        </div>
                    )}
                    <div className="admin-buttons-container">
                        {isAdmin && (
                            <button onClick={handleEditContent} className="edit-content-button">
                                <FaPen/> Edytuj tekst
                            </button>
                        )}
                    </div>
                    <Notification content={notificationContent} />
                    <div className="instruction-container">
                        <button
                            className="instruction-button"
                            onClick={() => setIsInstructionOpen(!isInstructionOpen)}
                        >
                            JAK TO DZIAŁA?
                            {isInstructionOpen ? <FaChevronUp/> : <FaChevronDown/>}
                        </button>
                        <div className={`instruction-content ${isInstructionOpen ? 'open' : ''}`}>
                            <h2>Instrukcja obsługi strony z wydarzeniami</h2>

                            <h3>1. Strona główna</h3>
                            <ul>
                                <li>Przeglądaj dostępne wydarzenia</li>
                                <li>Wybierz zakres dat, aby filtrować wydarzenia</li>
                                <li>Kliknij na dzień w kalendarzu, aby zobaczyć wydarzenia z danego miesiąca</li>
                                <li>U góry strony znajduje się przycisk "Sprawdź zapisy"</li>
                            </ul>

                            <h3>2. Strona wydarzenia</h3>
                            <ul>
                                <li>Zobacz opis wydarzenia</li>
                                <li>Rozróżnienie typów wydarzeń:
                                    <ul>
                                        <li>
                                            <span style={{
                                                display: 'inline-block',
                                                width: '20px',
                                                height: '20px',
                                                backgroundColor: 'rgba(21, 33, 61, 1)',
                                                verticalAlign: 'middle',
                                                marginRight: '5px'
                                            }}></span>
                                            Granatowy - wydarzenia z zapisami
                                        </li>
                                        <li>
                                            <span style={{
                                                display: 'inline-block',
                                                width: '20px',
                                                height: '20px',
                                                backgroundColor: '#fdc32e',
                                                verticalAlign: 'middle',
                                                marginRight: '5px'
                                            }}></span>
                                            Żółte - wydarzenia informacyjne bez zapisów
                                        </li>
                                    </ul>
                                </li>
                                <li>Dla granatowych wydarzeń:
                                    <ul>
                                        <li>Kliknij "Zapisz się"</li>
                                        <li>Wypełnij formularz</li>
                                        <li>Dalsze instrukcje otrzymasz e-mailem</li>
                                    </ul>
                                </li>
                                <li>Dla żółtych wydarzeń:
                                    <ul>
                                        <li>Możesz przyjść w dniu wydarzenia bez zapisów</li>
                                    </ul>
                                </li>
                            </ul>

                            <h3>3. Sprawdzanie zapisów</h3>
                            <ul>
                                <li>Kliknij "Sprawdź zapisy" na górze strony głównej</li>
                                <li>Otrzymasz e-mail z linkiem</li>
                                <li>Kliknij link w e-mailu</li>
                                <li>Zobacz listę wydarzeń, na które jesteś zapisany</li>
                            </ul>
                        </div>
                    </div>
                    <div className="header-container">
                        <h2 className="section-title">LISTA WYDARZEŃ</h2>
                        {isAdmin && (
                            <button onClick={handleCreateEvent} className="add-event-button">
                                <FaPlus/> Dodaj wydarzenie
                            </button>
                        )}
                    </div>
                    <div className="content-wrapper">
                        <div className="events-section">
                            <div className="filters">
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    placeholder="dzień/miesiąc/rok"
                                />
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    placeholder="dzień/miesiąc/rok"
                                />
                                <button onClick={toggleSortOrder} className="sort-button">
                                    Od {sortOrder === 'ASC' ? 'najbliższych' : 'najdalszych'}
                                </button>
                                <button onClick={handleShowAll} className="show-all-button">Pokaż wszystko</button>
                            </div>
                            <div className="events-list">
                                {eventsAPI.loading ? (
                                    <p>Wczytywanie wydarzeń...</p>
                                ) : eventsAPI.error ? (
                                    <p>błąd przy wczytywaniu wydarzeń: {eventsAPI.error}</p>
                                ) : (
                                    <div>
                                        {eventsAPI.data && eventsAPI.data.events ? (
                                            eventsAPI.data.events.map(renderEvent)
                                        ) : (
                                            <p>No events found.</p>
                                        )}
                                    </div>
                                )}
                            </div>
                            {eventsAPI.data && (
                                <div className="pagination">
                                    <button
                                        onClick={() => setPage(prev => Math.max(1, prev - 1))}
                                        disabled={page === 1}
                                    >
                                        Poprzednia
                                    </button>
                                    <span>Strona {page} z {eventsAPI.data.totalPages}</span>
                                    <button
                                        onClick={() => setPage(prev => Math.min(eventsAPI.data.totalPages, prev + 1))}
                                        disabled={page === eventsAPI.data.totalPages}
                                    >
                                        Następna
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="calendar-section">
                            <Calendar
                                events={calendarEvents}
                                onSelectDate={handleDateSelect}
                                selectedDate={selectedDate}
                                onMonthChange={handleMonthChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {showEditPopup && selectedEvent && (
                <Modal>
                    <div ref={editPopupRef}>
                        <EditPopup
                            showPopup={showEditPopup}
                            setShowPopup={setShowEditPopup}
                            eventId={selectedEvent.id}
                            eventName={selectedEvent.eventName}
                            eventPlace={selectedEvent.eventPlace}
                            eventDescription={selectedEvent.eventDescription}
                            eventDate={selectedEvent.eventDate}
                        />
                    </div>
                </Modal>
            )}
            {showCreateEventPopup && (
                <Modal>
                    <div ref={createEventPopupRef}>
                        <CreateEventPopup
                            showPopup={showCreateEventPopup}
                            setShowPopup={handleCloseCreateEventPopup}
                            onEventCreated={onEventCreated}
                        />
                    </div>
                </Modal>
            )}
            {isCopyEventOpen && selectedEvent && (
                <CopyEvent eventId={selectedEvent.id} onClose={handleCloseCopyEvent} onCopy={onCopyEvent} />
            )}
            {showNotificationEditor && (
                <Modal>
                    <div ref={notificationEditorRef}>
                        <NotificationEditor
                            onClose={handleCloseEditor}
                            onUpdate={handleUpdateNotification}
                            content={notificationContent}
                            setContent={setNotificationContent}
                        />
                    </div>
                </Modal>
            )}
            {showUserList && (
                <Modal>
                    <div ref={userListPopUpref}>
                        <UserList/>
                    </div>
                </Modal>
                )}
        </>
    );
};

export default WelcomePage;
