import "./mainPage.style.css";
import "../MainPhoto/mainPhoto.style.css";
import { useParams, useNavigate } from "react-router-dom";
import { EventElementsList } from "../Events/EventElementsList";
import useAPI from "../../hooks/UseAPI";
import React from 'react';
import { useEffect, useState, useRef } from "react";
import { EditPopup } from "../EditPopup/EditPopup";
import { IoIosSearch } from "react-icons/io";
import { GroupRegistration } from "../GroupRegistrationConfirm/GroupRegistration";
import { Modal } from "../Modal/Modal";
import MainPhoto from "../MainPhoto/MainPhoto";
import { EditInfoPopup } from "../EditPopup/EditInfoPopup";
import { WarningPopup } from "../Events/WarningPopup";
import { ImportantEventElements } from "../Events/ImportantEventElements";
import { jwtDecode } from "jwt-decode";


interface JWTPayload {
  iat: number;
  exp: number;
  roles: string[];
  username: string;
}
export const MainPage = () => {
  const { eventId: eventIdString, path } = useParams<{ eventId: string; path: string }>();
  const [date, setDate] = useState<string>();
  const [remainTime, setRemainTime] = useState<string>();
  const token = sessionStorage.getItem("jwtToken");
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [showEditInfoPopup, setShowEditInfoPopup] = useState(false);
  const [showGroupRegistrationPopup, setShowGroupRegistrationPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [notCofnrimedGroups, setNotCofnrimedGroups] = useState<boolean>(false);
  const [totalNumberOfRegistration, setTotalNumberOfRegistration] = useState('');
  const [totalRegistration, setTotalRegistration] = useState('');
  const [numberOfRegistrations, setNumberOfRegistrations] = useState('');
  const [facultySpots, setFacultySpots] = useState<any>(null);
  const [warningMessage, setWarningMessage] = useState("");
  const [activePopup, setActivePopup] = useState<string | null>(null);
  const [notificationStats, setNotificationStats] = useState({
    amountOfMailReceivers: 0,
    numberOfEmailsSent: 0
  });
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [userRoles, setUserRoles] = useState<string[]>([]);  const [isAdmin, setIsAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const eventId = Number(eventIdString);
  const initialFetchDone = useRef(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const hideAllElements = useAPI({
    url: "eventelement/changevisibilityofallelements",
    method: "POST",
    auth: true,
    token: token ? token : "",
  });
  const extractRolesFromToken = (token: string): string[] => {
    try {
      const decodedToken = jwtDecode<JWTPayload>(token);
      return decodedToken.roles || [];
    } catch (error) {
      console.error("Error decoding token:", error);
      return [];
    }
  };
  const handleHideAll = async () => {
    const response = await hideAllElements.call({
      body: {
        eventId,
        visibility: false
      }
    });

    if (response?.response?.ok) {
      window.location.reload();
    } else {
      showWarning("Wystąpił błąd podczas ukrywania elementów");
    }
  };

  const handleShowAll = async () => {
    const response = await hideAllElements.call({
      body: {
        eventId,
        visibility: true
      }
    });

    if (response?.response?.ok) {
      window.location.reload();
    } else {
      showWarning("Wystąpił błąd podczas pokazywania elementów");
    }
  };

  const event = useAPI(
      {
        url: `events/geteventinfo/${eventId}`,
        method: "GET",
        auth: true,
        token: token ? token : "",
      },
      true
  );

  const tokenValidate = useAPI({
    url: "user/validatetoken",
    method: "GET",
    auth: true,
    token: token ? token : "",
  });

  const handleNumberPeople = useAPI({
    url: `events/getregistrations/${eventId}`,
    method: "GET",
  });

  const getNotificationStats = useAPI({
    url: `email/getnotificationstatistics/${eventId}`,
    method: "GET",
    auth: true,
    token: token ? token : "",
  });

  const sendNotification = useAPI({
    url: "email/sendnotification",
    method: "POST",
    auth: true,
    token: token ? token : "",
  });

  useEffect(() => {
    if (event.error) {
      navigate("/");
    }
  }, [event.error, navigate]);

  useEffect(() => {
    if (!initialFetchDone.current && token) {
      tokenValidate.call().then((res) => {
        if (res?.response !== undefined && res.response.ok) {
          const roles = extractRolesFromToken(token);
          setUserRoles(roles);
          setIsAdmin(roles.includes('ROLE_ADMIN'));
          setIsUser(roles.includes('ROLE_USER'));

          handleNumberPeople.call().then((peopleRes) => {
            if (peopleRes?.response !== undefined && peopleRes.response.ok) {
              setTotalNumberOfRegistration(peopleRes.data.totalNumberOfRegistrations);
              setTotalRegistration(peopleRes.data.totalRegistrations);
              setNumberOfRegistrations(peopleRes.data.numberOfRegistrations);
              setFacultySpots(peopleRes.data.facultySpots);
            }
          });
          getNotificationStats.call().then((statsRes) => {
            if (statsRes?.response !== undefined && statsRes.response.ok) {
              setNotificationStats({
                amountOfMailReceivers: statsRes.data.amountOfMailReceivers,
                numberOfEmailsSent: statsRes.data.numberOfEmailsSent
              });
            }
          });
        } else {
          localStorage.removeItem('token');
          sessionStorage.clear();
          setUserRoles([]);
          setIsAdmin(false);
          setIsUser(false);
          window.location.reload();
        }
      });
      initialFetchDone.current = true;
    }
  }, [token, tokenValidate, handleNumberPeople, getNotificationStats]);

  useEffect(() => {
    if (event.data !== undefined) {
      const [dateStartPart, timeStartPart] = event.data.eventDate.split("T");
      setDate(dateStartPart);

      const eventDateTime = new Date(event.data.eventDate);
      const currentTime = new Date();
      const timeDifference = eventDateTime.getTime() - currentTime.getTime();
      const daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hoursRemaining = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutesRemaining = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );

      switch (true) {
        case daysRemaining === 0 && hoursRemaining < 5:
          setRemainTime(` ${hoursRemaining} godziny`);
          break;
        case daysRemaining === 0 && hoursRemaining < 20:
          setRemainTime(` ${hoursRemaining} godzin`);
          break;
        case daysRemaining === 0 && hoursRemaining < 24:
          setRemainTime(` ${hoursRemaining} godziny`);
          break;
        case daysRemaining < 0:
          setRemainTime(`Wydarzenie już się odbyło`);
          break;
        case daysRemaining < 1:
          setRemainTime(`${hoursRemaining}:${minutesRemaining}`);
          break;
        case daysRemaining === 1:
          setRemainTime(`${daysRemaining} dzień i ${hoursRemaining} godzin`);
          break;
        default:
          setRemainTime(`${daysRemaining} dni`);
          break;
      }
    }
  }, [event.data]);

  const handleGetSummary = () => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    fetch(`${process.env.REACT_APP_API_URL}events/getsummary/${eventId}`, {
      method: 'GET',
      headers: headers,
    })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'Podsumowanie.xlsx';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch(error => {
          console.error("Wystąpił błąd podczas wykonywania żądania", error);
        });
  };

  const showWarning = (message: string) => {
    console.log("Showing warning:", message);
    setWarningMessage(message);
    setActivePopup("warning");
  };

  const closeWarning = () => {
    setWarningMessage("");
    setActivePopup(null);
  };

  const handleEditEvent = () => {
    tokenValidate.call().then((res) => {
      if (res.response !== undefined && res.response.ok) {
        setShowPopup(true);
        setActivePopup("editEvent");
      }
    });
  };

  const handleEditInfoPopup = () => {
    tokenValidate.call().then((res) => {
      if (res.response !== undefined && res.response.ok) {
        setShowEditInfoPopup(true);
        setActivePopup("editInfo");
      }
    });
  };

  const handleGroupRegistrationPopup = (
      e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    setShowGroupRegistrationPopup(true);
    setActivePopup("groupRegistration");
  };

  const handleSendNotification = () => {
    setShowNotificationPopup(true);
  };

  const confirmSendNotification = async () => {
    const response = await sendNotification.call({
      body: { eventId }
    });

    setShowNotificationPopup(false);

    if (response?.response?.ok) {
      setNotificationMessage("Powiadomienia zostały pomyślnie zakolejkowane");
      setShowNotificationMessage(true);

      const statsRes = await getNotificationStats.call();
      if (statsRes?.response?.ok) {
        setNotificationStats({
          amountOfMailReceivers: statsRes.data.amountOfMailReceivers,
          numberOfEmailsSent: statsRes.data.numberOfEmailsSent
        });
      }

      setTimeout(() => {
        setShowNotificationMessage(false);
        setNotificationMessage("");
      }, 3000);
    } else {
      setNotificationMessage(response?.data?.error || "Wystąpił błąd podczas wysyłania powiadomień");
      setShowNotificationMessage(true);

      setTimeout(() => {
        setShowNotificationMessage(false);
        setNotificationMessage("");
      }, 3000);
    }
  };

  const closeAllPopups = () => {
    setShowPopup(false);
    setShowEditInfoPopup(false);
    setShowGroupRegistrationPopup(false);
    setWarningMessage("");
    setActivePopup(null);
  };

  return (
      <>
        <MainPhoto
            upperPopUpText={event.data?.upperPopUpText}
            lowerPopUpText={event.data?.lowerPopUpText}
            showAdminOptions={isAdmin}

            token={token}
        />
        <div className="scrolled-background">
          {event.data && (
              <div className="main">
                <div className="content">
                  {isAdmin  && (
                      <>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                          <button className="actionButton" onClick={handleGetSummary}>
                            Pobierz podsumowanie
                          </button>
                          <button className="actionButton" onClick={handleEditInfoPopup}>
                            Edytuj popup
                          </button>
                        </div>
                      </>
                  )}
                  <div></div>
                  {showEditInfoPopup && (
                      <Modal>
                        <EditInfoPopup
                            upperPopUpText={event.data?.upperPopUpText}
                            lowerPopUpText={event.data?.lowerPopUpText}
                            showPopup={showEditInfoPopup}
                            setShowPopup={setShowEditInfoPopup}
                            eventId={eventId}
                        />
                      </Modal>
                  )}
                  <div className="headerContainer">
                    <h2>{event.data.eventName}</h2>
                    {isAdmin  && (
                        <button className="actionButton" onClick={handleEditEvent}>
                          Edytuj wydarzenie
                        </button>
                    )}
                    {showPopup && (
                        <Modal>
                          <EditPopup
                              showPopup={showPopup}
                              setShowPopup={setShowPopup}
                              eventId={eventId}
                              eventName={event.data.eventName}
                              eventPlace={event.data.eventPlace}
                              eventDescription={event.data.eventDescription}
                              eventDate={event.data.eventDate}
                          />
                        </Modal>
                    )}
                  </div>
                  <div className="textContainer">
                    <div className="textItems">
                      <p>DATA</p>
                    </div>
                    <div className="textItems">
                      <p>MIEJSCE</p>
                    </div>
                    <div className="textItems">
                      <p>POZOSTAŁY CZAS</p>
                    </div>
                  </div>
                  <div className="info textContainer">
                    <div className="textItems">
                      <p>{date}</p>
                    </div>
                    <div className="textItems">
                      <p>{event.data.eventPlace}</p>
                    </div>
                    <div className="textItems">
                      <p>{remainTime}</p>
                    </div>
                  </div>
                  {(isUser || isAdmin) && (
                    <div className="numberPeople">
                      <div className="textItemsPeople">
                        Liczba rejestracji: {totalNumberOfRegistration}
                      </div>
                      {facultySpots?.OVERALL && (
                          <div className="textItemsPeople">
                            liczba ogólna zajętych miejsc: {facultySpots.OVERALL.taken}/{facultySpots.OVERALL.total}
                          </div>
                      )}
                      <div className="textItemsPeople">
                        Liczba unikatowych zapisów: {numberOfRegistrations}
                      </div>
                    </div>
                )}

                  {isAdmin && (
                      <>
                        <div className="numberPeople">
                          <div className="textItemsPeople">
                            Liczba odbiorców powiadomień: {notificationStats.amountOfMailReceivers}
                          </div>
                          <div className="textItemsPeople">
                            Liczba wysłanych powiadomień: {notificationStats.numberOfEmailsSent}
                          </div>
                          {notificationStats.numberOfEmailsSent < 2 && (
                              <div className="textItemsPeople">
                                <button className="actionButton" onClick={handleSendNotification}>
                                  Wyślij powiadomienie
                                </button>
                              </div>
                          )}
                        </div>
                        {showNotificationMessage && (
                            <div className="notification-message">
                              {notificationMessage}
                            </div>
                        )}
                      </>
                  )}
                  <div
                      className="description"
                      dangerouslySetInnerHTML={{ __html: event.data.eventDescription }}
                  ></div>
                </div>
                <div className="content">
                  <ImportantEventElements
                      eventId={Number(eventId)}
                      isAdminOptions={(isUser || isAdmin)}
                      isAdmin={isAdmin}
                      isUser={isUser}
                      showWarning={showWarning}
                  />
                  <div className="headerContainer">
                    <h2>LISTA WYDARZEŃ</h2>
                    {isAdmin && (
                        <div className="button-group">
                          <button
                              className="actionButton show-all"
                              onClick={handleShowAll}
                          >
                            Pokaż wszystkie
                          </button>
                          <button
                              className="actionButton hide-all"
                              onClick={handleHideAll}
                          >
                            Schowaj wszystkie
                          </button>
                        </div>
                    )}
                  </div>
                  {showGroupRegistrationPopup && (
                      <Modal>
                        <GroupRegistration
                            notCofnrimedGroups={notCofnrimedGroups}
                            email={email}
                            setVisiblePopup={setShowGroupRegistrationPopup}
                            eventId={eventId}
                            isAdmin={isAdmin}
                        />
                      </Modal>
                  )}
                  {(isUser || isAdmin) && (
                      <div className="emailSearch">
                        <div className="inputEmial">
                          <label htmlFor="email">
                            Wyszukaj osoby zapisane po E-mailu lub numerze telefonu
                          </label>
                          <input
                              onChange={(e) => setEmail(e.target.value)}
                              type="text"
                              placeholder="Email lub numer telefonu"
                              id="email"
                              name="email"
                              required
                          />
                        </div>
                        <div className="checkBox">
                          <label htmlFor="unconfirmed">
                            Tylko grupy niepotwierdzone
                          </label>
                          <input
                              onChange={(e) => setNotCofnrimedGroups(e.target.checked)}
                              type="checkbox"
                              id="unconfirmed"
                              name="unconfirmed"
                          />
                        </div>
                        <div>
                          <button onClick={handleGroupRegistrationPopup}>
                            {
                              <IoIosSearch
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    color: "#fff",
                                  }}
                              />
                            }
                          </button>
                        </div>
                      </div>
                  )}
                  <EventElementsList
                      showWarning={showWarning}
                      eventId={Number(eventId)}
                      facultySpots={facultySpots}
                      isAdminOptions={(isUser || isAdmin)}
                      isAdmin={isAdmin}
                      isUser={isUser}
                  />
                </div>
              </div>
          )}
        </div>
        {warningMessage && (
            <Modal>
              <WarningPopup
                  message={warningMessage}
                  onClose={closeWarning}
              />
            </Modal>
        )}
        {showNotificationPopup && (
            <Modal>
              <div className="popup-container">
                <h3>Potwierdzenie wysłania powiadomienia</h3>
                <p>Czy jesteś pewien że chcesz wysłać powiadomienie na temat tego wydarzenie, możesz wysłac 2 powiadomienia o każdym wydarzeniu</p>
                <div className="popup-buttons">
                  <button className="actionButton" onClick={confirmSendNotification}>
                    Tak, wyślij
                  </button>
                  <button className="actionButton" onClick={() => setShowNotificationPopup(false)}>
                    Nie wysyłaj
                  </button>
                </div>
              </div>
            </Modal>
        )}
      </>
  );
};

export default MainPage;