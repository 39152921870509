import { useEffect, useState } from "react";

export type callAPIProps = {
    url?: string,
    method?: string,
    body?: any,
    auth?: boolean,
    token?: string
}

export type callAPIFunction = (...args: any[]) => callAPIProps;


export type callAPISettings = callAPIProps | callAPIFunction;

const url = process.env.REACT_APP_API_URL
console.log(url)


export default function useAPI(props: callAPIProps, callInstantly = false) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>(undefined);
    const [error, setError] = useState<any>(undefined);
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        if (callInstantly) {
            callAPI();
        }
    }, [])

    const call = async (cp?: callAPISettings) => {
        if (!cp) return await callAPI();

        let callProps: callAPIProps = {};

        if (typeof cp === "function") {
            callProps = cp();
        } else {
            callProps = cp;
        }

        return await callAPI(callProps);
    }

    const callAPI = async (callProps?: callAPIProps) => {
        try {
            setLoading(true);

            let headers: { [key: string]: string } = {
                "Content-Type": "application/json",
            };

            if (props.auth && props.auth === true && props.token) {
                headers["Authorization"] = `Bearer ${props.token}`;
            }

            let response;

            if (props.method === "GET" || props.method === "DELETE") {
                const requestOptions = {
                    method: props.method,
                    headers: headers,
                    ...(props.method === "DELETE" && (props.body || callProps?.body) ? {
                        body: JSON.stringify(props.body ? props.body : callProps?.body)
                    } : {}),
                };
                response = await fetch(url + `${props.url}`, requestOptions);
            } else {
                response = await fetch(url + `${props.url}`, {
                    method: props.method,
                    headers: headers,
                    body: JSON.stringify(props.body ? props.body : callProps?.body),
                });
            }

            //console.log("response",response)
            const responseData = await response.json();
            //console.log("responseData",responseData)

            if (!response.ok) {
                if (responseData) {
                    setError(responseData)
                    return responseData;
                } else {
                    throw new Error(`Request Error: ${response.status} - ${response.statusText}`);
                }
            } else {
                setSuccess(true)
            }

            setData(responseData);

            //console.log("data",data)
            const fullResposne = { data: responseData, response: response }
            //console.log("fullResposne",fullResposne)
            return fullResposne;
        } catch (error) {

            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return { loading, data, error, success, callAPI, call };
}
