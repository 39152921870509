import { useEffect, useState } from "react";
import useAPI from "../../hooks/UseAPI";
import Rodo from "./Rodo.pdf";

export type RegistrationPopupProps = {
  eventElementId: number;
  eventElementName: string;
  setVisiblePopup: (val: boolean) => void;
};

export const RegistrationPopup = (props: RegistrationPopupProps) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [notificationAgreement, setNotificationAgreement] = useState<boolean>(false);

  const registration = useAPI({
    url: "userregistration/register",
    method: "POST",
  });

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const loseFocusePopup = document.querySelector(
          ".popupRegistrationContainer"
      );
      if (loseFocusePopup && !loseFocusePopup.contains(event.target)) {
        props.setVisiblePopup(false);
        setError("");
        setMessage("");
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [props]);

  const handleRegistrationSuccess = (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);

    const email = formData.get("email") as string;
    const name = formData.get("name") as string;
    const surname = formData.get("surname") as string;
    const phoneNumber = formData.get("phoneNumber") as string;

    let groupCount = "1";
    if (isDisabled) {
      groupCount = formData.get("groupCount") as string;
    }

    registration
        .call({
          body: {
            eventElementId: props.eventElementId,
            name: name,
            surname: surname,
            email: email,
            group: isDisabled,
            phoneNumber: phoneNumber,
            groupSize: parseInt(groupCount),
            nottificationAgreement: notificationAgreement
          },
        })
        .then((res) => {
          if (
              res !== undefined &&
              res.response !== undefined &&
              res?.response.ok
          ) {
            if (groupCount === "1") {
              setMessage("Sprawdź maila, aby potwierdzić swoją rejestrację");
            } else {
              setMessage(
                  "Sprawdź maila, aby potwierdzić swoją rejestrację. Po potwierdzeniu rejsetracji przyjdzie mail z dalszymi instrukcjami"
              );
            }
            setError("");
          } else {
            if (
                res?.status &&
                res?.status === "User already registered for event element"
            ) {
              setError("Zapisałeś się już na wydarzenie");
            } else if (
                res?.status &&
                res?.status === "not enough free slots for participants"
            ) {
              setError("Nie ma miejsc na wydarzeniu");
            } else if (res?.status && res?.status === "invalid email") {
              setError("Niepoprawny adres E-mail");
            } else if (res?.status && res?.status === "invalid phone number") {
              setError("Niepoprawny numer telefonu");
            } else if (
                res?.status &&
                res?.status === "cannot register for this event element"
            ) {
              setError("Nie można dokonywać zapisów na to wydarzenie");
            }
            else if (
                res?.status &&
                res?.status === "difference between start and end of the event is too short"
            ) {
              setError("Zbyt mała różnica czasu pomiędzy jednym z wydarzeń na które się zapisałes");
            } else if (res?.status && res?.status === "invalid group size") {
              setError("Liczba grupy musi być większa od 0");
            } else {
              setError("Wystąpił problem z rejestracją");
            }
            setMessage("");
          }
        });
  };

  const downloadRodoFile = () => {
    const url = "/components/Events/Rodo.pdf";
    window.open(url, "_blank");
  };

  return (
      <div className="popupRegistrationContainer">
        <div className="titleRegisPopup">
          <h3>{props.eventElementName}</h3>
        </div>
        <span>Formularz zapisu na zajęcia</span>
        <div className="contentRegisPopup">
          <form onSubmit={handleRegistrationSuccess}>
            <div className="input">
              <label htmlFor="email">E-mail:</label>
              <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="E-mail"
                  required
              />
            </div>
            <div className="input">
              <label htmlFor="name">Imię:</label>
              <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Imię"
                  required
              />
            </div>
            <div className="input">
              <label htmlFor="surname">Nazwisko:</label>
              <input
                  type="text"
                  id="surname"
                  name="surname"
                  placeholder="Nazwisko"
                  required
              />
            </div>
            <div className="input">
              <label htmlFor="phoneNumber">Numer Telefonu:</label>
              <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Nr Telefonu"
                  required
              />
            </div>
            <div>
              <input
                  style={{
                    height: "20px",
                    width: "20px",
                    margin: "8px",
                  }}
                  type="checkbox"
                  id="group"
                  name="group"
                  onClick={() => setIsDisabled(!isDisabled)}
              />
              <label style={{ fontSize: "16px" }} htmlFor="group">
                Zapis grupowy:
              </label>
            </div>
            <br />
            <div>
              <input
                  style={{
                    height: "20px",
                    width: "20px",
                    margin: "8px",
                  }}
                  type="checkbox"
                  id="notificationAgreement"
                  name="notificationAgreement"
                  checked={notificationAgreement}
                  onChange={(e) => setNotificationAgreement(e.target.checked)}
              />
              <label style={{ fontSize: "16px" }} htmlFor="notificationAgreement">
                Wyrażam zgodę na otrzymywanie powiadomień o wydarzeniach w przyszłości.
              </label>
            </div>
            <br />
            <div>
              <input
                  style={{
                    height: "20px",
                    width: "20px",
                    margin: "8px",
                  }}
                  type="checkbox"
                  id="rodo"
                  name="rodo"
                  required
              />
              <label style={{ fontSize: "16px" }} htmlFor="rodo">
                Akceptuję{" "}.
                <a
                    href={Rodo}
                    download="Regulamin Rodo"
                    target="_blank"
                    rel="noreferrer"
                >
                  regulamin Rodo
                </a>{" "}
              </label>
            </div>
            <br />
            {isDisabled && (
                <div className="input">
                  <label style={{ fontSize: "16px" }} htmlFor="groupCount">
                    Proszę wpisać ilość osób:
                  </label>
                  <input type="number" id="groupCount" name="groupCount" />
                </div>
            )}

            <button type="submit" className="buttonRegis">
              Zapisz się
            </button>
          </form>
          {message && <div className="succes">{message}</div>}
          {error && <div className="error">{error}</div>}
        </div>
      </div>
  );
};